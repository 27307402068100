<template>
  <div style="height: 100%">
    <v-chart
      :option="options"
      autoresize style="min-height: 250px"
    />
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { GridComponent, LegendComponent, TooltipComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'
use([GridComponent, LegendComponent, BarChart, CanvasRenderer, TooltipComponent])

export default {
  name: 'BarChart',
  components: {
    VChart
  },
  props: {
    question: Object
  },
  data() {
    return {
      options: {
        grid: { containLabel: true },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          inverse: true,
          animationDuration: 150,
          animationDurationUpdate: 150,
          axisLabel: {
            hideOverlap: false,
            interval: 0
          }
        },
        yAxis: {
          show: false,
          max: 'dataMax'
        },
        series: [
          {
            realtimeSort: false,
            name: 'Answers percentage',
            type: 'bar',
            data: [],
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top',
              fontSize: '18px',
              valueAnimation: true
            }
          }
        ],
        legend: {
          show: false
        },
        animationDuration: 0,
        animationDurationUpdate: 500,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      }
    }
  },
  methods: {
    resetChart() {
      this.options.series[0].data = []
      this.options.xAxis.data = []
      for (let i = 0; i < this.question.options.length; i++) {
        const color = this.getChartColor(i)
        const option = this.question.options[i]
        this.options.xAxis.data.push({
          value: option.value,
          textStyle: {
            fontSize: 8
          }
        })
        this.options.series[0].data.push({
          value: option.totalAnswers,
          itemStyle: {
            color: color
          }
        })
      }
      this.$getAnswerEventStreamByQuestionId(this.question.uuid).subscribe(r => {
        const index = this.question.options.findIndex(o => o.uuid === r.selectedOption)
        this.options.series[0].data[index].value += 1
      })
      this.$getRemoveAnswerEventStreamByQuestionId(this.question.uuid).subscribe(r => {
        const index = this.question.options.findIndex(o => o.uuid === r.answer.selectedOption)
        if (this.options.series[0].data[index].value > 0) {
          this.options.series[0].data[index].value -= 1
        }
      })
    }
  },
  watch: {
    question: function(newValue, oldValue) {
      this.resetChart()
    }
  },
  mounted() {
    this.resetChart()
  }
}
</script>
<style lang="scss">

</style>
