<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <div v-for="question in form.questions" :key="question.uuid" class="row">
          <SingleSelectionQuestion :question="question" :disabled="question.disabled" v-if="question.type === 'SINGLE_SELECTION'" @answer="submitAnswer(question, $event)"/>
          <MultipleSelectionQuestion :question="question" :disabled="question.disabled" v-if="question.type === 'MULTIPLE_SELECTION'" @answer="submitAnswer(question, $event)"/>
          <SingleWordQuestion :question="question" :disabled="question.disabled" v-if="question.type === 'SINGLE_WORD'" @answer="submitAnswer(question, $event)"/>
          <SingleNumberQuestion :question="question" :disabled="question.disabled" v-if="question.type === 'SINGLE_NUMBER'" @answer="submitAnswer(question, $event)"/>
          <FreeTextQuestion :question="question" :disabled="question.disabled" v-if="question.type === 'FREE_TEXT'" @answer="submitAnswer(question, $event)"/>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getAnsweredQuestions, findForm, answerQuestion, removeAnswer } from '@/api/backend.js'
import SingleSelectionQuestion from '@/components/questions/SingleSelectionQuestion.vue'
import MultipleSelectionQuestion from '@/components/questions/MultipleSelectionQuestion.vue'
import SingleWordQuestion from '@/components/questions/SingleWordQuestion.vue'
import SingleNumberQuestion from '@/components/questions/SingleNumberQuestion.vue'
import FreeTextQuestion from '@/components/questions/FreeTextQuestion.vue'

export default {
  name: 'FormDetails',
  components: {
    SingleSelectionQuestion,
    MultipleSelectionQuestion,
    SingleWordQuestion,
    SingleNumberQuestion,
    FreeTextQuestion
  },
  data() {
    return {
      loading: false,
      fingerprint: null,
      form: {
        id: null,
        name: null,
        open: false,
        questions: []
      }
    }
  },
  mounted() {
    if (this.$route.params !== null && this.$route.params.id !== null) {
      this.loading = true
      this.getFingerprint().then(r => {
        console.log(r)
        this.fingerprint = r.visitorId
        this.loadForm(this.$route.params.id)
      })
    }
    if (this.$store.getters['security/apiToken'] === null) {
      this.$connect('')
    }
  },
  methods: {
    loadForm(id) {
      this.loading = true
      findForm(id).then(r => {
        const formData = r.data
        formData.questions.forEach(question => {
          question.answers = []
          question.disabled = !formData.open // populate initial property
        })
        getAnsweredQuestions(id, this.fingerprint).then(response => {
          response.data.forEach(answer => {
            formData.questions.forEach(question => {
              if (question.uuid === answer.questionId) {
                // question.disabled = true
                question.answers.push(answer)
              }
            })
          })
          this.form = formData
        })
      }).catch(e => this.showErrorNotification('An error occured, try again later'))
        .then(e => { this.loading = false })
    },
    submitAnswer(question, answerData) {
      console.log(question, answerData)
      this.loading = true
      let removeAnswerRequest = Promise.resolve(null)
      if (answerData.removedAnswer !== null) {
        const index = question.answers.findIndex(a => a.id === answerData.removedAnswer)
        question.answers.splice(index, 1)
        removeAnswerRequest = removeAnswer(answerData.removedAnswer)
      }
      removeAnswerRequest.then(() => {
        if (answerData.answer !== null) {
          answerQuestion(this.form.id, question.uuid, answerData.answer, this.fingerprint).then(r => {
            question.answers.push(r.data)
          })
        }
      }).then(() => { this.loading = false })
    }
  }
}
</script>
<style lang="scss">

</style>
