<template>
  <div class="col-12">
    <ValidationObserver ref="observer" tag="form" class="row" @submit.prevent="submitQuestion">
      <div class="col-12 col-md-5">
        <div class="text-h6">{{question.value}}</div>
        <ValidationProvider rules="required" name="Answer option" v-slot="{ errors, valid }">
          <v-checkbox v-model="selectedOption" :disabled="disabled" dense v-for="option in question.options" :key="option.uuid" :label="option.value" :value="option.uuid" :success="valid" hide-details></v-checkbox>
          <div v-if="errors.length > 0" class="text-caption red--text"></div>
        </ValidationProvider>
        <!-- <v-btn type="submit" class="mt-3" v-if="!disabled" dense small color="success">Submit</v-btn> -->
      </div>
      <div class="col-0 col-md-7 hidden-sm-and-down">
        <BarChart :question="question" v-if="question.chartType === 'BAR'"/>
        <DonutChart :question="question" v-if="question.chartType === 'DONUT'"/>
        <PieChart :question="question" v-if="question.chartType === 'PIE'"/>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import DonutChart from '@/components/charts/DonutChart.vue'
import PieChart from '@/components/charts/PieChart.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'MultipleSelectionQuestion',
  components: {
    BarChart,
    DonutChart,
    PieChart,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    question: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: []
    }
  },
  mounted() {
    this.question.answers.forEach(a => {
      this.selectedOption.push(a.selectedOption)
    })
  },
  methods: {
    async submitQuestion(event) {
      const valid = await await this.$refs.observer.validate()
      if (valid) {
        this.selectedOption.forEach(o => this.$emit('answer', o))
      }
    }
  },
  watch: {
    selectedOption: function(newValue, oldValue) {
      let toRemove = []
      let toAdd = []
      if (newValue.length < oldValue.length) {
        toRemove = oldValue.filter(x => !newValue.includes(x))
      }
      toAdd = newValue.filter(x => !oldValue.includes(x))
      toRemove.forEach(a => {
        const answer = this.question.answers.find(e => e.selectedOption === a)
        if (answer !== undefined) {
          this.$emit('answer', { answer: null, removedAnswer: answer.id })
        }
      })
      toAdd.forEach(a => {
        this.$emit('answer', { answer: a, removedAnswer: null })
      })
    }
  }
}
</script>
<style lang="scss">

</style>
