<template>
  <div class="col-12">
    <ValidationObserver ref="observer" tag="form" class="row" @submit.prevent="submitQuestion">
      <div class="col-12 col-md-5">
        <div class="text-h6">{{question.value}}</div>
        <ValidationProvider rules="required" name="Value" v-slot="{ errors, valid }">
          <v-textarea v-model="selectedOption" :disabled="question.answers.length > 0" label="Answer:" :success="valid" :error-messages="errors"></v-textarea>
        </ValidationProvider>
        <v-btn type="submit" v-if="question.answers.length === 0" dense small color="success">Submit</v-btn>
      </div>
      <div class="col-0 col-md-7 hidden-sm-and-down">
        <v-list v-if="answers.length > 0">
          <v-subheader>Answers</v-subheader>
          <v-list-item v-for="(answer, i) in answers" :key="answer.id">
            <v-list-item-icon>{{i+1}})</v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{answer.selectedOption}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getQuestionAnswers } from '@/api/backend.js'
export default {
  name: 'FreeTextQuestion',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    question: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: null,
      answers: []
    }
  },
  mounted() {
    if (this.question.answers.length > 0) {
      this.selectedOption = this.question.answers[0].selectedOption
    }
    if (this.$store.getters['security/apiToken'] !== null) {
      this.loadAnswers()
    }
  },
  methods: {
    loadAnswers() {
      getQuestionAnswers(this.question.uuid).then(r => {
        this.answers = r.data
      }).catch(e => this.showErrorNotification('Could not load answers for question: ' + this.question.value))
    },
    submitQuestion(event) {
      if (this.selectedOption !== null) {
        this.submited = false
        this.$emit('answer', {
          removedAnswer: null,
          answer: this.selectedOption
        })
      }
    }
  }
}
</script>
<style lang="scss">

</style>
