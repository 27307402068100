<template>
  <div style="height: 100%">
    <v-chart
      :option="options"
      autoresize style="min-height: 300px"
    />
  </div>
</template>

<script>
import { use } from 'echarts/core'
import 'echarts-wordcloud'
import { GridComponent, LegendComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'
use([GridComponent, LegendComponent, BarChart, CanvasRenderer])

export default {
  name: 'WordClouldChart',
  components: {
    VChart
  },
  props: {
    question: Object
  },
  data() {
    return {
      options: {
        series: [{
          type: 'wordCloud',
          shape: 'circle',
          keepAspect: false,
          sizeRange: [12, 40],
          layoutAnimation: true,
          textStyle: {
            fontFamily: 'sans-serif',
            fontWeight: 'bold'
          },
          emphasis: {
            focus: 'self',
            textStyle: {
              textShadowBlur: 10,
              textShadowColor: '#333'
            }
          },
          data: []
        }],
        legend: {
          show: false
        },
        tooltip: {
          trigger: 'item'
        },
        animationDuration: 0,
        animationDurationUpdate: 500,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      }
    }
  },
  methods: {
    resetChart() {
      this.options.data = []
      for (let i = 0; i < this.question.options.length; i++) {
        const option = this.question.options[i]
        const color = this.getChartColor(i)
        const index = this.options.series[0].data.findIndex(o => o.name === option.value)
        if (index === -1) {
          this.options.series[0].data.push({
            value: option.totalAnswers,
            name: option.value,
            textStyle: {
              color: color
            }
          })
        } else {
          this.options.series[0].data[index].value += 1
        }
      }
      this.$getAnswerEventStreamByQuestionId(this.question.uuid).subscribe(r => {
        const index = this.options.series[0].data.findIndex(o => o.name === r.selectedOption)
        const color = this.getChartColor(Math.floor(Math.random() * 7))
        if (index === -1) {
          this.options.series[0].data.push({
            value: 1,
            name: r.selectedOption,
            textStyle: {
              color: color
            }
          })
        } else {
          this.options.series[0].data[index].value += 1
        }
      })
      this.$getRemoveAnswerEventStreamByQuestionId(this.question.uuid).subscribe(r => {
        const index = this.question.options.findIndex(o => o.uuid === r.answer.selectedOption)
        if (this.options.series[0].data[index].value > 0) {
          this.options.series[0].data[index].value -= 1
        }
      })
    }
  },
  watch: {
    question: function(newValue, oldValue) {
      this.resetChart()
    }
  },
  mounted() {
    this.resetChart()
  }
}
</script>
<style lang="scss">

</style>
