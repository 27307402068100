<template>
  <div class="col-12">
    <ValidationObserver ref="observer" tag="form" class="row" @submit.prevent="submitQuestion">
      <div class="col-12 col-md-5">
        <div class="text-h6">{{question.value}}</div>
        <ValidationProvider rules="required" name="Answer option" v-slot="{ errors, valid }">
          <v-radio-group v-model="selectedOption" :success="valid" :error-messages="errors">
            <v-radio :disabled="disabled" v-for="option in question.options" :key="option.uuid" :label="option.value" :value="option.uuid"></v-radio>
          </v-radio-group>
        </ValidationProvider>
      </div>
      <div class="col-0 col-md-7 hidden-sm-and-down">
        <BarChart :question="question" v-if="question.chartType === 'BAR'"/>
        <DonutChart :question="question" v-if="question.chartType === 'DONUT'"/>
        <PieChart :question="question" v-if="question.chartType === 'PIE'"/>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import DonutChart from '@/components/charts/DonutChart.vue'
import PieChart from '@/components/charts/PieChart.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'SingleSelectionQuestion',
  components: {
    BarChart,
    DonutChart,
    PieChart,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    question: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: null
    }
  },
  mounted() {
    if (this.question.answers.length > 0) {
      this.selectedOption = this.question.answers[0].selectedOption
    }
  },
  watch: {
    selectedOption: function(newValue, oldValue) {
      const data = {
        answer: newValue,
        removedAnswer: null
      }
      const answer = this.question.answers.find(a => a.selectedOption === oldValue)
      if (answer !== undefined) {
        data.removedAnswer = answer.id
      }
      this.$emit('answer', data)
    }
  }
}
</script>
<style lang="scss">

</style>
