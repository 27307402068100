<template>
  <div class="col-12">
    <ValidationObserver ref="observer" tag="form" class="row" @submit.prevent="submitQuestion">
      <div class="col-12 col-md-5">
        <div class="text-h6">{{question.value}}</div>
        <ValidationProvider rules="required" name="Value" v-slot="{ errors, valid }">
          <v-text-field v-model="selectedOption" :disabled="question.answers.length > 0" label="Answer:" :success="valid" :error-messages="errors"></v-text-field>
        </ValidationProvider>
        <v-btn type="submit" v-if="question.answers.length === 0" dense small color="success">Submit</v-btn>
      </div>
      <div class="col-0 col-md-7 hidden-sm-and-down">
        <WordClouldChart :question="question"/>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import WordClouldChart from '@/components/charts/WordClouldChart.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'SingleWordQuestion',
  components: {
    WordClouldChart,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    question: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: null
    }
  },
  methods: {
    submitQuestion(event) {
      if (this.selectedOption !== null) {
        this.submited = false
        this.$emit('answer', {
          removedAnswer: null,
          answer: this.selectedOption
        })
      }
    }
  }
}
</script>
<style lang="scss">

</style>
