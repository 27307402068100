<template>
  <div style="height: 100%">
    <v-chart
      :option="options"
      autoresize style="min-height: 300px"
    />
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { LabelLayout } from 'echarts/features'
import { TooltipComponent } from 'echarts/components'
import VChart from 'vue-echarts'
use([PieChart, CanvasRenderer, LabelLayout, TooltipComponent])

export default {
  name: 'PieChart',
  components: {
    VChart
  },
  props: {
    question: Object
  },
  data() {
    return {
      options: {
        series: [
          {
            realtimeSort: false,
            name: 'Answers percentage',
            type: 'pie',
            radius: '50%',
            avoidLabelOverlap: false,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: []
          }
        ],
        tooltip: {
          trigger: 'item'
        },
        animationDuration: 0,
        animationDurationUpdate: 500,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      }
    }
  },
  methods: {
    resetChart() {
      this.options.series[0].data = []
      for (let i = 0; i < this.question.options.length; i++) {
        const color = this.getChartColor(i)
        const option = this.question.options[i]
        this.options.series[0].data.push({
          value: option.totalAnswers,
          name: option.value,
          itemStyle: {
            color: color
          }
        })
      }
      this.$getAnswerEventStreamByQuestionId(this.question.uuid).subscribe(r => {
        const index = this.question.options.findIndex(o => o.uuid === r.selectedOption)
        this.options.series[0].data[index].value += 1
      })
      this.$getRemoveAnswerEventStreamByQuestionId(this.question.uuid).subscribe(r => {
        const index = this.question.options.findIndex(o => o.uuid === r.answer.selectedOption)
        if (this.options.series[0].data[index].value > 0) {
          this.options.series[0].data[index].value -= 1
        }
      })
    }
  },
  watch: {
    question: function(newValue, oldValue) {
      this.resetChart()
    }
  },
  mounted() {
    this.resetChart()
  }
}
</script>
<style lang="scss">

</style>
