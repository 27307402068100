<template>
  <div style="height: 100%">
    <v-chart
      :option="options"
      autoresize style="min-height: 250px"
    />
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { GaugeChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'
use([GaugeChart, CanvasRenderer])

export default {
  name: 'AverageChart',
  components: {
    VChart
  },
  props: {
    question: Object
  },
  data() {
    return {
      options: {
        series: [{
          type: 'gauge',
          pointer: {
            show: false
          },
          progress: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          data: [{
            value: 0.0,
            name: 'Average',
            title: {
              offsetCenter: ['0%', '-25%']
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', '10%']
            }
          }],
          title: {
            fontSize: 30
          },
          detail: {
            width: 150,
            height: 150,
            fontSize: 30,
            color: 'gray',
            borderColor: 'gray',
            borderRadius: 20,
            borderWidth: 1,
            formatter: '{value}'
          }
        }
        ],
        animationDuration: 0,
        animationDurationUpdate: 500,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      }
    }
  },
  methods: {
    resetChart() {
      this.options.series[0].data[0].value = 0.0
      this.question.answers.forEach(a => {
        this.options.series[0].data[0].value += parseInt(a.selectedOption)
      })
      if (this.question.totalAnswers > 0) {
        this.options.series[0].data[0].value /= this.question.totalAnswers
      }
      this.$getAnswerEventStreamByQuestionId(this.question.uuid).subscribe(r => {
        this.question.totalAnswers++
        if (this.question.totalAnswers === 1) {
          this.options.series[0].data[0].value = parseFloat(r.selectedOption)
        } else {
          const value = (parseFloat(r.selectedOption) + this.options.series[0].data[0].value) / 2
          this.options.series[0].data[0].value = value.toFixed(2)
        }
      })
      this.$getRemoveAnswerEventStreamByQuestionId(this.question.uuid).subscribe(r => {
        // reverse average here
        this.question.totalAnswers--
        if (this.question.totalAnswers === 1) {
          this.options.series[0].data[0].value = 0
        } else {
          const current = this.options.series[0].data[0].value
          const old = current * 2 - parseFloat(r.answer.selectedOption)
          this.options.series[0].data[0].value = old.toFixed(2)
        }
      })
    }
  },
  watch: {
    question: function(newValue, oldValue) {
      this.resetChart()
    }
  },
  mounted() {
    this.resetChart()
  }
}
</script>
<style lang="scss">

</style>
