<template>
  <div class="col-12">
    <ValidationObserver ref="observer" tag="form" class="row" @submit.prevent="submitQuestion">
      <div class="col-12 col-md-5">
        <div class="text-h6">{{question.value}}</div>
        <ValidationProvider rules="required|numeric|max:5" name="Value" v-slot="{ errors, valid }">
          <v-text-field type="text" v-model="selectedOption" :disabled="disabled" label="Answer:" :success="valid" :error-messages="errors" :append-icon="'mdi-send'" @click:append="submitQuestion"></v-text-field>
        </ValidationProvider>
      </div>
      <div class="col-0 col-md-7 hidden-sm-and-down">
        <AverageChart :question="question"/>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import AverageChart from '@/components/charts/AverageChart.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'SingleNumberQuestion',
  components: {
    AverageChart,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    question: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: null
    }
  },
  mounted() {
    if (this.question.answers.length > 0) {
      this.selectedOption = this.question.answers[0].selectedOption
    }
  },
  methods: {
    submitQuestion(event) {
      const data = {
        removedAnswer: null,
        answer: null
      }
      if (this.question.answers.length > 0) {
        data.removedAnswer = this.question.answers[0].id
      }
      if (this.selectedOption !== null) {
        data.answer = this.selectedOption
      }
      this.$emit('answer', data)
    }
  }
}
</script>
<style lang="scss">

</style>
